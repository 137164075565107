import * as moment from 'moment';
import {DatePipe} from '@angular/common';
import 'moment/locale/es';

export function transfromStringtoDate(dateString: string, addDays?:boolean, cant?:number)
{
 let pipe =new DatePipe('ES');
 let dayWithPipe, monthWithPipe, yearWithPipe : any;
 let fecha: any
 let momentVariable = moment(dateString)
 let stringValue = momentVariable.format('YYYY-MM-DD')

 
 if(addDays)
 {
    var varDate=new Date(stringValue)
    varDate.setDate( varDate.getDate() + cant)
    stringValue=varDate.toString()
   
 }

 dayWithPipe= pipe.transform(stringValue, 'dd')
 monthWithPipe = pipe.transform(stringValue,'MM')
 yearWithPipe = pipe.transform(stringValue, 'yyyy')

 fecha= dayWithPipe +'/' +  monthWithPipe + '/' + yearWithPipe

 return fecha
}

export function transfromStringtoDateEN(dateString: string)
{
 let pipe =new DatePipe('ES');
 let dayWithPipe, monthWithPipe, yearWithPipe : any;
 let fecha: any
 let momentVariable = moment(dateString)
 let stringValue = momentVariable.format('YYYY-MM-DD')


 dayWithPipe= pipe.transform(stringValue, 'dd')
 monthWithPipe = pipe.transform(stringValue,'MM')
 yearWithPipe = pipe.transform(stringValue, 'yyyy')

 fecha= yearWithPipe +'-'+  monthWithPipe + '-' + dayWithPipe 

 return fecha
}

export function addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
}


export function transfromStringtoDateTime( time: string)
{
 let pipe =new DatePipe('ES');
 let hora: any
 let dateString='1900-01-01' + ' ' + time
 let momentVariable = moment(dateString)
 let stringValue = momentVariable.format('HH:mm')


 hora= stringValue

 return hora
}

/*export function addHourToStr(val: string, char: string, duration?:number){
 let pos = val.indexOf(char)
 let hourStr= val.substring(0,pos)
 let minStr= val.substring(pos+1,val.length)
 let hour: number
 hour= Number(hourStr) + duration
 if (hour<10)
     {hourStr='0'+ hour + ':' + minStr}
    else{
      hourStr= hour + ':' + minStr
    }
 
 return hourStr
}*/

export function calculateNotBusinessHours(businessHours: Array<any>):Array<any>{
  var notBusinessHours: Array<any>=[]
  for (let i=0; i<businessHours.length;i++)
  {
    if ((i+1)<businessHours.length-1 || (i+1)==businessHours.length-1 )
    {
      if (businessHours[i].endTime!=businessHours[i+1].startTime)
      {
        notBusinessHours.push({'startTime':businessHours[i].endTime ,'endTime':businessHours[i+1].startTime})
      }
    }
     
  }

  return notBusinessHours;
}

export function addHourToStr(val: string, char: string, duration?:any){
  let pos = val.indexOf(char)
  let hourStr= val.substring(0,pos)
  let minStr= val.substring(pos+1,val.length)
  let hour, minutes: number

  
  if (duration) {

    if (duration.dif_minutes==0 && duration.dif_hours==1)
    {
      hour= Number(hourStr) + 1
    }
    else{
      minutes= duration.dif_minutes + Number(minStr)
      
        if (minutes==60){
          minStr= '00'
          hour= duration.dif_hours + Number(hourStr) + 1

        }
        
        if (minutes>60) {
          hour= duration.dif_hours + Number(hourStr) + 1
          minStr = (minutes - 60).toString()
        }

        if (minutes<60 && minutes!=0) {
          hour= duration.dif_hours + Number(hourStr)
          minStr= minutes.toString()}
          
        if (minutes==0){
          hour= duration.dif_hours + Number(hourStr)
          minStr= '00'
        }

        if (hour>=20 && minutes!=0){
          hour=20
          minStr='00'
        }
      
    }
    
  }
  else
     hour= Number(hourStr) + 1
  
  if (hour<10)
      {hourStr='0'+ hour + ':' + minStr}
     else{
       hourStr= hour + ':' + minStr
     }

  return hourStr
 }


export function calculateTimeofRangeHours(hour1: any, hour2: any){
  var dif_minutes: number=0;
  var dif_hours: number=0;
  var duration: any={}

  var [hours1, minutes1] = hour1.split(':');
  var [hours2, minutes2] = hour2.split(':');

  if (minutes2>minutes1)
    dif_minutes= minutes2-minutes1
  else{
    //dif_minutes= minutes1-minutes2
    dif_minutes= 60-(minutes1-minutes2)
    //if (dif_minutes!=0)
    hours2=hours2-1
    
  }
    
  dif_hours = hours2-hours1

  duration={
    'dif_hours': dif_hours,
    'dif_minutes': dif_minutes
  }
    
  return duration
}

export function hourMinStr(val: string, char: string){
  let pos = val.indexOf(char)
  let hourStr= val.substring(0,pos)
  let rest= val.substring(pos+1,val.length)
  pos= rest.indexOf(char)
  let minStr=rest.substring(0,pos)
  hourStr= hourStr + ':' + minStr
     
  return hourStr
 }

export function getDayWeek(dateString: string)
{
 let dayWeek: any
 dayWeek=new Date(dateString).getDay()
 return dayWeek
}

export function getDayNumber(dateString: string)
{
 let dayWeek: any
 dayWeek=new Date(dateString).getDate()
 return dayWeek
}

export function getMonthNumber(dateString: string)
{
  let month: any
  month=new Date(dateString).getMonth()+1
  return month
}

export function getYearNumber(dateString: string){
  let year: any
  year=new Date(dateString).getFullYear()
  return year

}



export function transfromDateTimeEN(dateString: string, time: string)
{
 let fecha: string
 let pos= dateString.indexOf('/')
 let day=dateString.substring(0,pos)
 dateString= dateString.substring(pos+1,dateString.length)
 pos= dateString.indexOf('/')
 let month=dateString.substring(0,pos)
 dateString= dateString.substring(pos+1,dateString.length)
 let year= dateString.substring(0,4)
    if (day.length==1)
        {day= '0'+day}
 fecha=year +'-'+ month + '-' + day
 let hora= time 
 return fecha + ' ' + hora

}

export function transfromDateTimeENT(dateString: string, time: string)
{
 let fecha: string
 let pos= dateString.indexOf('/')
 let day=dateString.substring(0,pos)
 dateString= dateString.substring(pos+1,dateString.length)
 pos= dateString.indexOf('/')
 let month=dateString.substring(0,pos)
 dateString= dateString.substring(pos+1,dateString.length)
 let year= dateString.substring(0,4)
    if (day.length==1)
        {day= '0'+day}
 fecha=year +'-'+ month + '-' + day
 let hora= time 
 return fecha + 'T' + hora

}



export function transfromDateEN(dateString: string)
{
 let fecha: string
 let pos= dateString.indexOf('/')
 let day=dateString.substring(0,pos)
 dateString= dateString.substring(pos+1,dateString.length)
 pos= dateString.indexOf('/')
 let month=dateString.substring(0,pos)
 dateString= dateString.substring(pos+1,dateString.length)
 let year= dateString.substring(0,4)
    if (day.length==1)
        {day= '0'+day}
 fecha=year +'-'+ month + '-' + day
 return fecha 

}

export function parseDateTime( datetime: string)
{
 let time: any
 let date: any
 let pos= datetime.indexOf('T')
 date=datetime.substring(0,pos)
 let pos2= datetime.indexOf('+')

 if (pos2!=-1)
    time=datetime.substring(pos+1,pos2-3)
 else
    time=datetime.substring(pos+1,datetime.length-3)

 date= date + ' ' + time

 return date
}

export function parseDateTimeT( datetime: string)
{
 let time: any
 let date: any
 let pos= datetime.indexOf('T')
 date=datetime.substring(0,pos)
 let pos2= datetime.indexOf('+')
 time=datetime.substring(pos+1,pos2-3)


 date= date + 'T' + time + ':00'

 return date
}

export function parseDateTimeZ( datetime: string)
{
 let date: any
 let pos= datetime.indexOf('Z')
 date=datetime.substring(0,pos-7)

 return date
}


export function parseDateToTime( datetime: string)
{
 let time: any
 let pos= datetime.indexOf('T')
 let pos2= datetime.indexOf('+')


 time=datetime.substring(pos+1,datetime.length)

 if(time.length==8 && pos2==-1)
 {
  time=time.substring(0,time.length-3)
 }

 if (pos2>0)
 {
  time=datetime.substring(pos+1,pos2-3)
 }

 return time
}

export function getTimeNumber( datetime: string)
{
 let time: any
 let pos= datetime.indexOf(':')
 
 time=datetime.substring(0,pos)

 return time
}

export function getMinutesNumber( datetime: string)
{
 let minutes: any
 let pos= datetime.indexOf(':')
 
 minutes=datetime.substring(pos+1, datetime.length)

 return minutes
}



export function parseDate( datetime: string)
{
 let date: any
 let pos= datetime.indexOf('T')
 if (pos!=-1) date=datetime.substring(0,pos)
  else date= datetime
 
 date= date 

 return date
}


export function recalculateArrHours(val: string, arrHours:Array<any>){
  
  let arrHoursRecalculated: Array<any>=[]
  var keep:boolean
  for (let i=0; i<arrHours.length; i++)
  {
    if(arrHours[i]==val)
    { //console.log(val,arrHours[i], 'entra cuando son iguales')
      arrHoursRecalculated.push(arrHours[i])
      keep=true}
    else if(arrHours[i]!=val && keep){
      arrHoursRecalculated.push(arrHours[i])
    }
    
  }
  return arrHoursRecalculated

}

export function getTodayShortdateFormatEN(){
  var dayWithPipe: any
  var monthWithPipe:any
  var yearWithPipe: any
  let pipe =new DatePipe('ES');
  var today: Date 
  today= new Date()

  dayWithPipe= pipe.transform(today, 'dd')
  monthWithPipe = pipe.transform(today, 'MM')
  yearWithPipe = pipe.transform(today, 'yyyy')
  const currentDate: any= yearWithPipe + '-' + monthWithPipe + '-' + dayWithPipe
  return currentDate
}



export function getNumberWeekDay(dateString:any)
{let posDay:number=0
 let pipe =new DatePipe('ES');
 let month, year: any;
 let momentVariable = moment(dateString)
 let stringValue = momentVariable.format('YYYY-MM-DD')
 month = pipe.transform(stringValue,'MM')
 year = pipe.transform(stringValue,'YYYY')
 const startDate = moment.utc(`${year}/${month}/01`)
 const endDate = startDate.clone().endOf('month')


 const diffDays = endDate.diff(startDate, 'days', true)
 const numberDays = Math.round(diffDays);


 const arrayDays = Object.keys([...Array(numberDays)]).map((a: any) => {
      a = parseInt(a) + 1;
      const dayObject = moment(`${year}-${month}-${a}`);

        if (dayObject.format("dddd")== momentVariable.format("dddd"))
        {
            posDay=posDay+1
            return {
            name: dayObject.format("dddd"),
            value: a,
            indexWeek: dayObject.isoWeekday(),
            posDay:posDay,
            monthName: dayObject.format("MMMM")
           }
           
        }
        else
        {
            return {
                name: dayObject.format("dddd"),
                value: a,
                indexWeek: dayObject.isoWeekday(),
                posDay:0,
                monthName: dayObject.format("MMMM")
                
              };
        }

   
    
   });

 return arrayDays
}

export function validateDatesEN(date1: any, date2: any, char:any):boolean{
  let d, m, y, d1, m1,y1
  y = parseInt(date1.split(char)[0]);
  m = parseInt(date1.split(char)[1]);
  d = parseInt(date1.split(char)[2]);

  y1 = parseInt(date2.split(char)[0]);
  m1 = parseInt(date2.split(char)[1]);
  d1 = parseInt(date2.split(char)[2]);



  if(d1 < d){
    if(m1<m || m1==m)
    return false;
    else
    return true
  }
  else{
    if(d1==d){
      if(m1<m)
      { 
      return false;
      }
      else{
        return true;
      }
    }
    else{
      return true;
    }
  }
}

export function validateDates(date1: any, date2: any, char:any){

  let d, m, y, d1, m1,y1
  d = parseInt(date1.split(char)[0]);
  m = parseInt(date1.split(char)[1]);
  y = parseInt(date1.split(char)[2]);

  d1 = parseInt(date2.split(char)[0]);
  m1 = parseInt(date2.split(char)[1]);
  y1 = parseInt(date2.split(char)[2]);


if(y==y1){
  if(d1 < d){
    if(m1<m || m1==m)
    return false;
    else
    return true
  }
  else{
    if(d1==d){
      if(m1<m)
      { 
      return false;
      }
      else{
        return true;
      }
    }
    else{
      return true;
    }
  }
}
else if (y>y1)
  return false
else if (y<y1)
  return true
 
}




